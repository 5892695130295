.datatable-header-cell-label{
  font-weight: 700;
  padding-left: 1.5rem;
}

.avatar-container, .avatar-container-mini {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.avatar-container{
  height: 150px;
  width: 150px;
}
.avatar-container-mini{
  height: 50px;
  width: 50px;
}
.avatar-img {
  width: 100% !important;
  object-fit: cover;
  margin: 0 !important;
}

// error innput
.invalid-tooltip{
  margin-left: 50%;
}

// Data Table
.data-table-rows .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row{
  padding: 20px !important;
  display: flex;
  align-items: center;
}

.data-table-rows .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell, .data-table-rows .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell{
  display: flex;
  align-items: center;
  padding-left: 0.3rem !important;
  padding-right: 0.5rem !important;
}

.datatable-body-cell-label{
  height: fit-content;
}
